<template>
    <nav
        class="navbar navbar-expand navbar-light navbar-bg top-header">
        <a @click.prevent="toggleSidebar"
            class="sidebar-toggle js-sidebar-toggle">
            <i class="hamburger align-self-center"></i>
        </a>
        <form class="d-none d-sm-inline-block top-search-box">
            <div class="input-group border-bottom">
                <input type="text" class="form-control px-2 border-0" v-model="search" placeholder="Search">
                <div class="input-group-append">
                    <button class="btn"
                        @click="searchData()"
                        @keyup.enter="searchData()"
                        type="button">
                        <svg data-v-6c94dcf0=""
                            class="svg-inline--fa fa-magnifying-glass"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="magnifying-glass"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            data-fa-i2svg="">
                            <path
                                fill="currentColor"
                                d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z">
                            </path>
                        </svg>
                    </button>
                </div>
            </div>
        </form>
        <div class="navbar-collapse collapse">
            <ul class="navbar-nav navbar-align">
                <li class="nav-item">
                    <div class="avatar mx-1">
                        <img v-if="imageShow !== null"
                            :src="`${baseUrl}` + imageShow"
                            class="img-fluid"
                            alt="" />
                        <img v-else
                            src="../assets/img/no-avatar.png"
                            class="img-fluid"
                            alt="" />
                    </div>
                </li>

                <li class="nav-item dropdown username-dropdown">
                    <a class="nav-link dropdown-toggle"
                        href="#"
                        id="resourcesDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false">
                        {{ authUser.first_name }}
                    </a>
                    <div class="dropdown-menu p-0" aria-labelledby="resourcesDropdown">
                        <a @click.prevent="logout" class="dropdown-item" href="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-power"><path d="M18.36 6.64a9 9 0 1 1-12.73 0"></path><line x1="12" y1="2" x2="12" y2="12"></line></svg>
                            <span class="ms-2">Logout</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { EventBus } from "@/event-bus";
import store from "@/store";
import { Notification } from "element-ui";
import { mapActions, mapGetters } from "vuex";
import axios from "axios";

export default {
    name: "Navbar",
    computed: {
        ...mapGetters("auth", ["authUser"]),
        ...mapGetters("notifications", ["notifications", "notificationCount"]),
    },
    data() {
        return {
            imageShow: null,
            search: null,
            baseUrl: `${process.env.VUE_APP_IMAGE_BASE_URL}`,
        }
    },
    methods: {
        ...mapActions("notifications", [
            "getNotifications",
            "countNotification",
            "markAllAsRead",
            "markAsRead",
        ]),

        toggleSidebar() {
            EventBus.$emit("toggleSidebar", true);
        },

        logout() {
            store.dispatch("auth/logout").then(() => {
                Notification.success("Logout successful");
                this.$router.push("/signin");
            });
            // window.location.href = '/signin';
        },

        markRead(notification) {
            this.markAsRead({ notification: notification, notificationType: 'notification' })
        },

        getAuthUser() {
            axios.get('/account/profile').then((response) => {
                this.imageShow = response.data.data.profile_picture
            }).catch((error) => {
                console.log(error)
            })
        },
        searchData() {
            var searchData = this.search
            this.$router.push({ path: `/searchResult?q=${searchData}` });
        }
    },
    mounted() {
        // it's used because we are not using vuex store
        // কারন বায়ার খাটাইস, সময় দেয় নাই তেমন একটা। সময় পেলে ভিউ এক্স ব্যবহার করবো
        EventBus.$on("profile-avatar-updated", () => {
            this.getAuthUser();
        });
        this.getAuthUser();
        // this.getNotifications();
        this.countNotification("notification");
    },

};
</script>

<style scoped>
.list-group-item {
    background-color: #fff;
    padding: 5px 10px;
    border-color: #cdcdcd;
    font-size: 12px;
    font-weight: 500;
}

.list-group-item.unread {
    background-color: #edfff6;
    padding: 5px 10px;
}

.notification-dropdown {
    max-height: 85vh;
    overflow-y: auto;
    top: 46px;
}

/* width */
.notification-dropdown::-webkit-scrollbar {
    width: 3px;
}

.dropdown-menu-header {
    padding: 5px 0px;
}

/* Track */
.notification-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.notification-dropdown::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.notification-dropdown::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.avatar {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid #00b050;
    overflow: hidden;
}

.avatar img {
    height: 35px;
    width: 35px;
    border-radius: 50%;
}

@media (max-width: 575.98px) {
    .dropdown.username-dropdown {
        position: relative;
    }

    .notification-dropdown {
        top: 57px;
        right: 24px;
    }
}
</style>