<template>
  <nav
    :class="{ 'd-none': !showSidebar, 'sidebar-mobile': sidebarMobile }"
    id="sidebar" class="sidebar js-sidebar">
    <div class="sidebar-content js-simplebar">
      <router-link class="sidebar-brand" to="/">
        <img class="site-logo"
          src="../assets/img/longleap-logo.png"
          alt="Longleap" />
      </router-link>
      <span class="close-menu"
        :class="(sidebarMobile) ? 'd-inline-block' : 'd-none'"
        @click="closeSidebar">
        <icons name="clode-button" />
      </span>
      <ul class="sidebar-nav">
        <li class="nav-header">
          <span
            class="nav-label">NAVIGATION</span>
        </li>
        <li class="sidebar-item without-submenu">
          <router-link class="sidebar-link"
            to="/main-dashboard">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="dashboard" />
            <span
              class="align-middle">Dashboard</span>
          </router-link>
        </li>
        <li class="sidebar-item">
          <a data-bs-target="#team-menu"
            data-bs-toggle="collapse"
            class="sidebar-link collapsed"
            :class="{ 'exact-active active': this.selectPath === '/team/create' || this.selectPath === '/team/projects' }">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="users" />
            <span
              class="align-middle">My Teams</span>
          </a>
          <ul id="team-menu"
            class="sidebar-dropdown list-unstyled collapse"
            :class="{ 'show': this.selectPath === '/team/create' || this.selectPath === '/team/projects' }"
            data-bs-parent="#sidebar">
            <li class="sidebar-item">
              <router-link to="/team/create"
                class="sidebar-link sub-link">
                Add New Team
              </router-link>
            </li>
            <li v-for="team in teams"
              :key="team.id" class="sidebar-item">
              <a :data-bs-target="'#team-menu-item-' + team.id"
                data-bs-toggle="collapse"
                class="sidebar-link sub-link collapsed"
                :class="{ 'exact-active active': getSelectTeamPath() === '/team/projects?team=' + team.name }">{{
                    team.name
                }}
              </a>
              <ul
                :id="'team-menu-item-' + team.id"
                class="sidebar-dropdown list-unstyled collapse"
                :class="{ 'show': getSelectTeamPath() === '/team/projects?team=' + team.name }"
                data-bs-parent="#team-menu">
                <li class="sidebar-item">
                  <router-link
                    :to="'/team/projects?team=' + team.name + '&id=' + team.id + '&menu=projects&active=active_projects'"
                    class="sidebar-link sub-sub-link"
                    :class="{ 'exact-active active': getIsActiveTeamProjects() }">
                    Project Summary
                  </router-link>
                </li>
                <li class="sidebar-item">
                  <router-link
                    :to="'/team/projects?team=' + team.name + '&id=' + team.id + '&menu=createPublicProject&active=primary_info'"
                    class="sidebar-link sub-sub-link"
                    :class="{ 'exact-active active': getIsActiveTeamPublicProject() }">
                    Create Public Project
                  </router-link>
                </li>
                <li class="sidebar-item">
                  <router-link
                    :to="'/team/projects?team=' + team.name + '&id=' + team.id + '&menu=createPrivateProject&active=primary_info'"
                    class="sidebar-link sub-sub-link"
                    :class="{ 'exact-active active': getIsActiveTeamPrivateProject() }">
                    Create Private Project
                  </router-link>
                </li>
                <!-- <li class="sidebar-item">
                  <router-link
                    :to="'/team/projects?team=' + team.name + '&id=' + team.id + '&menu=joinWithExternalLink'"
                    class="sidebar-link sub-link"
                    :class="{ 'exact-active active': (getPathQueryTeamMenu() === '/team/projects?team=' + team.name + '&id=' + team.id + '&menu=joinWithExternalLink') || (getPathQueryTeamMenu() === '/team/projects?menu=joinWithExternalLinkDescription&team=' + team.name + '&id=' + team.id) }">
                    Join Project with ID#
                  </router-link>
                </li> -->
              </ul>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a data-bs-target="#personal-menu"
            data-bs-toggle="collapse"
            class="sidebar-link collapsed"
            :class="{ 'exact-active active': this.selectPath === '/personal-project/dashboard' }">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="user" />
            <span
              class="align-middle">Personal Projects</span>
          </a>
          <ul id="personal-menu"
            class="sidebar-dropdown list-unstyled collapse"
            :class="{ 'show': this.selectPath === '/personal-project/dashboard' }"
            data-bs-parent="#sidebar">
            <li class="sidebar-item">
              <router-link
                to="/personal-project/dashboard?menu=projects&active=active_projects"
                class="sidebar-link sub-link"
                :class="{ 'exact-active active': this.selectPathQueryOnlyMenu === '/personal-project/dashboard?menu=projects' }">
                Project Summary
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link
                to="/personal-project/dashboard?menu=createPublicProject&active=primary_info"
                class="sidebar-link sub-link"
                :class="{ 'exact-active active': this.selectPathQueryOnlyMenu === '/personal-project/dashboard?menu=createPublicProject' }">
                Create Public Project
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link
                to="/personal-project/dashboard?menu=createPrivateProject&active=primary_info"
                class="sidebar-link sub-link"
                :class="{ 'exact-active active': this.selectPathQueryOnlyMenu === '/personal-project/dashboard?menu=createPrivateProject' }">
                Create Private Project
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link
                to="/personal-project/dashboard?menu=joinWithExternalLink"
                class="sidebar-link sub-link"
                :class="{ 'exact-active active': (this.selectPathQueryOnlyMenu === '/personal-project/dashboard?menu=joinWithExternalLink') || (this.selectPathQueryOnlyMenu === '/personal-project/dashboard?menu=joinWithExternalLinkDescription') }">
                Join Project with ID#
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item">
          <a data-bs-target="#pricing-plan-menu"
            data-bs-toggle="collapse"
            class="sidebar-link collapsed"
            :class="{ 'exact-active active': this.selectPath === '/pricing' }">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="credit_card" />
            <span class="align-middle">Pricing
              Plan</span>
          </a>
          <ul id="pricing-plan-menu"
            class="sidebar-dropdown list-unstyled collapse"
            :class="{ 'show': this.selectPath === '/pricing' }"
            data-bs-parent="#sidebar">
            <li class="sidebar-item">
              <router-link
                to="/pricing?menu=plan&submenu=personal"
                class="sidebar-link sub-link">
                Personal
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link
                to="/pricing?menu=plan&submenu=team"
                class="sidebar-link sub-link">
                Team
              </router-link>
            </li>
          </ul>
        </li>
        <li class="sidebar-item without-submenu">
          <router-link class="sidebar-link"
            to="/account-status">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="account" />
            <span class="align-middle">Account
              Status</span>
          </router-link>
        </li>
        <li class="sidebar-item without-submenu">
          <router-link class="sidebar-link"
            to="/alert">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="bell" />
            <span
              class="align-middle">Notifications
              {{ alertCount > 0 ?
                  '(' + alertCount + ')'
                  : ''
              }}</span>
          </router-link>
        </li>
        <li class="sidebar-item">
          <a data-bs-target="#settings-menu"
            data-bs-toggle="collapse"
            class="sidebar-link collapsed"
            :class="{ 'exact-active active': this.selectPath === '/settings' || this.selectPath === '/profile' }">
            <span class="shape1"></span>
            <span class="shape2"></span>
            <icons name="setting" />
            <span
              class="align-middle">Settings</span>
          </a>
          <ul id="settings-menu"
            class="sidebar-dropdown list-unstyled collapse"
            :class="{ 'show': this.selectPath === '/settings' || this.selectPath === '/profile'}"
            data-bs-parent="#sidebar">
            <li class="sidebar-item">
              <router-link
                to="/profile"
                class="sidebar-link sub-link">
                Profile
              </router-link>
            </li>
            <li class="sidebar-item">
              <router-link
                to="/settings?menu=cards&submenu=cards"
                class="sidebar-link sub-link">
                Saved Credit Cards
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import {
  mapActions,
  mapGetters
} from "vuex";
import {
  EventBus
} from "./../event-bus";
import axios from "axios";
import Icons from "@/components/common/Icons";
export default {
  name: "Sidebar",
  components: {
    Icons
  },
  data() {
    return {
      showSidebar: true,
      teams: [],
      selectPath: '',
      selectPathQuery: '',
      selectPathQueryRemovedActive: '',
      selectTeamPath: '',
      personalMenuActive: false,
      selectPathQueryOnlyMenu: '',
      selectPathQueryTeamMenu: '',
      windowWidth: window.innerWidth,
      sidebarMobile: false,
      isActiveTeamProjects: false,
      isActiveTeamPublicProject: false,
      isActiveTeamPrivateProject: false,
    };
  },
  computed: {
    ...mapGetters("notifications", ["alertCount"]),
  },
  mounted() {
    EventBus.$on("toggleSidebar", (showSidebar) => {
      this.showSidebar = !this.showSidebar;
    });
    EventBus.$on("team-created", (showSidebar) => {
      this.getTeams();
    });
    this.getTeams();
    this.countNotification('alert');
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  methods: {
    ...mapActions("notifications", ["countNotification"]),
    getTeams() {
      axios.get("/teams").then((res) => {
        // console.log(res)
        this.teams = res.data;
      }).catch((err) => {
        console.log(err.response);
      });
    },
    getSelectTeamPath() {
      return this.selectTeamPath;
    },
    getPathQueryRemovedActive() {
      return this.selectPathQueryRemovedActive;
    },
    getPathQueryOnlyMenu() {
      return this.selectPathQueryOnlyMenu;
    },
    getPathQueryTeamMenu() {
      return this.selectPathQueryTeamMenu;
    },
    getIsActiveTeamProjects() {
      return this.isActiveTeamProjects;
    },
    getIsActiveTeamPublicProject() {
      return this.isActiveTeamPublicProject;
    },
    getIsActiveTeamPrivateProject() {
      return this.isActiveTeamPrivateProject;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth > 991) {
        this.sidebarMobile = false;
        this.showSidebar = true
      }
      else {
        this.sidebarMobile = true;
        this.showSidebar = false
      }
    },
    closeSidebar() {
      this.showSidebar = false
    }
  },
  watch: {
    '$route.path': {
      handler(path) {
        this.selectPath = path;
        this.personalMenuActive = path === '/personal-project/dashboard';
        if (this.sidebarMobile) {
          this.showSidebar = false;
        }
      },
      immediate: true,
      deep: true
    },
    '$route.query': {
      handler(query) {
        var str = [];
        var strRemovedActive = [];
        var strPathQueryOnlyMenu = [];
        var strPathQueryTeamMenu = [];
        var strTeamPath = [];
        for (var index in query)
          if (query.hasOwnProperty(index)) {
            if (index != 'active') {
              strRemovedActive.push(encodeURIComponent(index) + "=" + encodeURIComponent(query[index]));
            }
            if (index == 'team') {
              strTeamPath.push(encodeURIComponent(index) + "=" + encodeURIComponent(query[index]));
            }
            if (index == 'menu') {
              strPathQueryOnlyMenu.push(encodeURIComponent(index) + "=" + encodeURIComponent(query[index]));
            }
            if (index == 'team' || index == 'id' || index == 'menu') {
              strPathQueryTeamMenu.push(encodeURIComponent(index) + "=" + encodeURIComponent(query[index]));
            }
            str.push(encodeURIComponent(index) + "=" + encodeURIComponent(query[index]));
          }
        this.selectPathQuery = this.selectPath + "?" + str.join("&");
        this.selectPathQueryRemovedActive = this.selectPath + "?" + strRemovedActive.join("&");
        this.selectTeamPath = this.selectPath + "?" + strTeamPath.join("&");
        this.selectPathQueryOnlyMenu = this.selectPath + "?" + strPathQueryOnlyMenu.join("&");
        this.selectPathQueryTeamMenu = this.selectPath + "?" + strPathQueryTeamMenu.join("&");
        if (query.hasOwnProperty('team') && query.hasOwnProperty('id') && query.hasOwnProperty('menu')) {
          this.isActiveTeamProjects = query.menu == 'projects' ? true : false;
          this.isActiveTeamPublicProject = query.menu == 'createPublicProject' ? true : false;
          this.isActiveTeamPrivateProject = query.menu == 'createPrivateProject' ? true : false;
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style scoped="css">

</style>