<template>
    <div class="d-inline-block">
        <svg v-if="name === 'envelop'"
            xmlns="http://www.w3.org/2000/svg"
            width="14" height="11"
            viewBox="0 0 14 11">
            <path id="Vector"
                d="M12.25,0H1.75A1.752,1.752,0,0,0,0,1.75v7.5A1.752,1.752,0,0,0,1.75,11h10.5A1.752,1.752,0,0,0,14,9.25V1.75A1.752,1.752,0,0,0,12.25,0Zm-.443,2.895-4.5,3.5a.5.5,0,0,1-.614,0l-4.5-3.5a.5.5,0,1,1,.614-.789L7,5.367l4.193-3.261a.5.5,0,0,1,.614.789Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'menu-watch'"
            xmlns="http://www.w3.org/2000/svg"
            width="16.838" height="16.838"
            viewBox="0 0 16.838 16.838">
            <g id="layer1"
                transform="translate(0 -604.362)">
                <path id="rect3341"
                    d="M8.419,0a8.419,8.419,0,1,0,8.419,8.419A8.4,8.4,0,0,0,8.419,0ZM8.4,1.186a1.2,1.2,0,0,1,1.221,1.22V7.216h2.405a1.2,1.2,0,1,1,0,2.406H8.419a1.2,1.2,0,0,1-1.2-1.2V2.405A1.2,1.2,0,0,1,8.4,1.186Z"
                    transform="translate(0 604.362)"
                    :fill="fill" />
            </g>
        </svg>

        <svg v-if="name === 'dashboard'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-home">
            <path
                d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z">
            </path>
            <polyline
                points="9 22 9 12 15 12 15 22">
            </polyline>
        </svg>
        <svg v-if="name === 'files'" class="mx-2"
            xmlns="http://www.w3.org/2000/svg"
            width="14" height="14"
            viewBox="0 0 14 14">
            <path id="Vector"
                d="M12.385,0H3.769A1.617,1.617,0,0,0,2.154,1.615v.539H1.615A1.617,1.617,0,0,0,0,3.769v8.615A1.617,1.617,0,0,0,1.615,14h8.615a1.617,1.617,0,0,0,1.615-1.615v-.539h.539A1.617,1.617,0,0,0,14,10.231V1.615A1.617,1.617,0,0,0,12.385,0ZM9.692,11.577a.269.269,0,0,1-.269.269h-7a.269.269,0,0,1-.269-.269v-.539a.269.269,0,0,1,.269-.269h7a.269.269,0,0,1,.269.269Zm0-2.154a.269.269,0,0,1-.269.269h-7a.269.269,0,0,1-.269-.269V8.885a.269.269,0,0,1,.269-.269h7a.269.269,0,0,1,.269.269Zm0-2.154a.269.269,0,0,1-.269.269h-7a.269.269,0,0,1-.269-.269V6.731a.269.269,0,0,1,.269-.269h7a.269.269,0,0,1,.269.269Zm0-2.154a.269.269,0,0,1-.269.269h-7a.269.269,0,0,1-.269-.269V4.577a.269.269,0,0,1,.269-.269h7a.269.269,0,0,1,.269.269Zm3.231,5.115a.539.539,0,0,1-.539.539h-.539v-7a1.617,1.617,0,0,0-1.615-1.615h-7V1.616a.539.539,0,0,1,.539-.539h8.615a.539.539,0,0,1,.539.539Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'folder'" class="ms-3"
            xmlns="http://www.w3.org/2000/svg"
            width="12" height="9.599"
            viewBox="0 0 12 9.599">
            <path id="Vector"
                d="M9.8.8H5.9A.6.6,0,0,1,5.569.7L4.874.235A1.394,1.394,0,0,0,4.1,0H2.2A1.4,1.4,0,0,0,.8,1.4V2H11.2A1.244,1.244,0,0,0,9.8.8Zm.394,8.8H1.805a1.4,1.4,0,0,1-1.4-1.379L0,4.1V4.1A1.2,1.2,0,0,1,1.2,2.8h9.6A1.2,1.2,0,0,1,12,4.1V4.1l-.405,4.118a1.4,1.4,0,0,1-1.4,1.379Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'star'" class="ms-3"
            xmlns="http://www.w3.org/2000/svg"
            width="12" height="11.459"
            viewBox="0 0 12 11.459">
            <path id="Vector"
                d="M11.612,4.035l-3.6-.523L6.407.252a.454.454,0,0,0-.813,0L3.985,3.512l-3.6.523a.453.453,0,0,0-.251.774l2.6,2.538L2.125,10.93a.453.453,0,0,0,.657.477L6,9.715l3.218,1.692a.453.453,0,0,0,.657-.477L9.26,7.346l2.6-2.538a.454.454,0,0,0-.251-.774Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'watch'" class="ms-3"
            xmlns="http://www.w3.org/2000/svg"
            width="12" height="12"
            viewBox="0 0 12 12">
            <path id="Vector"
                d="M5.994,0A6,6,0,1,0,12,6,6,6,0,0,0,5.994,0ZM8.4,8.4a.6.6,0,0,1-.846,0L5.58,6.426A.6.6,0,0,1,5.4,6V3.6a.6.6,0,0,1,1.2,0V5.754l1.8,1.8A.6.6,0,0,1,8.4,8.4Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'delete'" class="ms-3"
            xmlns="http://www.w3.org/2000/svg"
            width="11.104" height="12"
            viewBox="0 0 11.104 12">
            <path id="Vector"
                d="M5.552,0A1.97,1.97,0,0,1,7.515,1.791h3.053a.537.537,0,0,1,.073,1.07l-.073.005h-.418l-.908,7.866A1.433,1.433,0,0,1,7.818,12H3.286a1.433,1.433,0,0,1-1.424-1.268L.954,2.866H.537A.537.537,0,0,1,.005,2.4L0,2.328A.537.537,0,0,1,.464,1.8l.073-.005H3.59A1.97,1.97,0,0,1,5.552,0ZM4.478,4.478a.349.349,0,0,0-.352.257l-.006.057V9.179l.006.056a.37.37,0,0,0,.7,0l.006-.057V4.792L4.83,4.735A.35.35,0,0,0,4.478,4.478Zm2.149,0a.349.349,0,0,0-.352.257l-.006.057V9.179l.006.056a.37.37,0,0,0,.7,0l.006-.057V4.792l-.006-.057a.349.349,0,0,0-.352-.256Zm-1.075-3.4a.9.9,0,0,0-.878.716H6.43a.9.9,0,0,0-.878-.716Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'link'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-link-2">
            <path
                d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3">
            </path>
            <line x1="8" y1="12" x2="16" y2="12">
            </line>
        </svg>

        <svg v-if="name === 'user'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-user">
            <path
                d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2">
            </path>
            <circle cx="12" cy="7" r="4"></circle>
        </svg>
        <svg v-if="name === 'users'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-users">
            <path
                d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2">
            </path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 0 0-3-3.87">
            </path>
            <path d="M16 3.13a4 4 0 0 1 0 7.75">
            </path>
        </svg>
        <svg v-if="name === 'profile'" width="15"
            height="17" viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.25 6.5C11.25 7.09334 11.0741 7.67336 10.7444 8.16671C10.4148 8.66006 9.94623 9.04458 9.39805 9.27164C8.84987 9.4987 8.24667 9.55811 7.66473 9.44236C7.08279 9.3266 6.54824 9.04088 6.12868 8.62132C5.70912 8.20176 5.4234 7.66721 5.30765 7.08527C5.19189 6.50333 5.2513 5.90013 5.47836 5.35195C5.70543 4.80377 6.08994 4.33524 6.58329 4.00559C7.07664 3.67595 7.65666 3.5 8.25 3.5C9.04565 3.5 9.80871 3.81607 10.3713 4.37868C10.9339 4.94129 11.25 5.70435 11.25 6.5ZM9.75 6.5C9.75 6.79667 9.66203 7.08668 9.49721 7.33335C9.33238 7.58003 9.09812 7.77229 8.82403 7.88582C8.54994 7.99935 8.24834 8.02906 7.95737 7.97118C7.66639 7.9133 7.39912 7.77044 7.18934 7.56066C6.97956 7.35088 6.8367 7.08361 6.77882 6.79264C6.72095 6.50166 6.75065 6.20006 6.86418 5.92597C6.97771 5.65189 7.16997 5.41762 7.41665 5.2528C7.66332 5.08797 7.95333 5 8.25 5C8.64783 5 9.02936 5.15804 9.31066 5.43934C9.59197 5.72064 9.75 6.10218 9.75 6.5Z"
                :fill="fill" />
            <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.25 0.5C6.61831 0.5 5.02325 0.983854 3.66655 1.89038C2.30984 2.7969 1.25242 4.08537 0.627998 5.59286C0.00357461 7.10035 -0.159803 8.75915 0.158525 10.3595C0.476853 11.9598 1.26259 13.4298 2.41637 14.5836C3.57016 15.7374 5.04017 16.5231 6.64051 16.8415C8.24085 17.1598 9.89965 16.9964 11.4071 16.372C12.9146 15.7476 14.2031 14.6902 15.1096 13.3335C16.0161 11.9767 16.5 10.3817 16.5 8.75C16.5 6.56196 15.6308 4.46354 14.0836 2.91637C12.5365 1.36919 10.438 0.5 8.25 0.5ZM1.5 8.75C1.49795 10.2565 2.00189 11.7201 2.931 12.906C3.55693 12.0854 4.36269 11.4192 5.28634 10.9586C6.20999 10.498 7.22694 10.2554 8.25903 10.2493C9.29112 10.2432 10.3109 10.4739 11.2399 10.9235C12.1689 11.3731 12.9824 12.0298 13.618 12.843C14.2678 11.9907 14.7053 10.9959 14.8942 9.94089C15.0832 8.88592 15.0183 7.80112 14.7048 6.77623C14.3913 5.75134 13.8382 4.81584 13.0914 4.04712C12.3446 3.27841 11.4254 2.69858 10.41 2.35562C9.39461 2.01265 8.31213 1.91641 7.25214 2.07485C6.19215 2.23329 5.18513 2.64186 4.31439 3.26675C3.44366 3.89165 2.73424 4.7149 2.24483 5.6684C1.75543 6.6219 1.50011 7.67824 1.5 8.75ZM8.25 15.5C6.70184 15.5014 5.20063 14.9684 4 13.991C4.48109 13.3034 5.11999 12.741 5.86315 12.3512C6.60632 11.9613 7.43211 11.7552 8.27132 11.7502C9.11053 11.7452 9.93871 11.9415 10.6865 12.3225C11.4342 12.7035 12.0798 13.2581 12.569 13.94C11.3571 14.9508 9.82814 15.503 8.25 15.5Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'password'" width="15"
            height="19" viewBox="0 0 24 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 6C6 6.19778 5.94135 6.39112 5.83147 6.55557C5.72159 6.72002 5.56541 6.84819 5.38268 6.92388C5.19996 6.99957 4.99889 7.01937 4.80491 6.98079C4.61093 6.9422 4.43275 6.84696 4.29289 6.70711C4.15304 6.56725 4.0578 6.38907 4.01922 6.19509C3.98063 6.00111 4.00043 5.80004 4.07612 5.61732C4.15181 5.43459 4.27998 5.27841 4.44443 5.16853C4.60888 5.05865 4.80222 5 5 5C5.26522 5 5.51957 5.10536 5.70711 5.29289C5.89464 5.48043 6 5.73478 6 6ZM9 7C9.19778 7 9.39112 6.94135 9.55557 6.83147C9.72002 6.72159 9.84819 6.56541 9.92388 6.38268C9.99957 6.19996 10.0194 5.99889 9.98079 5.80491C9.9422 5.61093 9.84696 5.43275 9.70711 5.29289C9.56725 5.15304 9.38907 5.0578 9.19509 5.01922C9.00111 4.98063 8.80004 5.00043 8.61732 5.07612C8.43459 5.15181 8.27841 5.27998 8.16853 5.44443C8.05865 5.60888 8 5.80222 8 6C8 6.26522 8.10536 6.51957 8.29289 6.70711C8.48043 6.89464 8.73478 7 9 7ZM14 6C14 6.19778 13.9414 6.39112 13.8315 6.55557C13.7216 6.72002 13.5654 6.84819 13.3827 6.92388C13.2 6.99957 12.9989 7.01937 12.8049 6.98079C12.6109 6.9422 12.4327 6.84696 12.2929 6.70711C12.153 6.56725 12.0578 6.38907 12.0192 6.19509C11.9806 6.00111 12.0004 5.80004 12.0761 5.61732C12.1518 5.43459 12.28 5.27841 12.4444 5.16853C12.6089 5.05865 12.8022 5 13 5C13.2652 5 13.5196 5.10536 13.7071 5.29289C13.8946 5.48043 14 5.73478 14 6ZM20 5H16V7H20V5Z"
                :fill="fill" />
            <path fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2L0 10C0 10.5304 0.210714 11.0391 0.585786 11.4142C0.960859 11.7893 1.46957 12 2 12H22C22.5304 12 23.0391 11.7893 23.4142 11.4142C23.7893 11.0391 24 10.5304 24 10V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0H2ZM22 2H2V10H22V2Z"
                :fill="fill" />
        </svg>

        <svg v-if="name === 'credit_card'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-credit-card">
            <rect x="1" y="4" width="22"
                height="16" rx="2" ry="2">
            </rect>
            <line x1="1" y1="10" x2="23" y2="10">
            </line>
        </svg>
        <svg v-if="name === 'save'" width="16"
            height="16" viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.177 7.4848L9.188 4.9988C9.0813 4.86572 8.94617 4.75821 8.79251 4.68415C8.63885 4.61009 8.47057 4.57136 8.3 4.5708H1.143C0.839857 4.5708 0.549131 4.69122 0.334777 4.90558C0.120423 5.11993 0 5.41066 0 5.7138V14.8568C0 15.1599 0.120423 15.4507 0.334777 15.665C0.549131 15.8794 0.839857 15.9998 1.143 15.9998H10.286C10.5891 15.9998 10.8799 15.8794 11.0942 15.665C11.3086 15.4507 11.429 15.1599 11.429 14.8568V8.1998C11.4288 7.93974 11.3399 7.68753 11.177 7.4848ZM4 5.7138H7.429V7.4278H4V5.7138ZM7.429 14.8568H4V11.4278H7.429V14.8568ZM8.572 14.8568V11.4278C8.572 11.1247 8.45158 10.8339 8.23722 10.6196C8.02287 10.4052 7.73214 10.2848 7.429 10.2848H4C3.69686 10.2848 3.40613 10.4052 3.19178 10.6196C2.97742 10.8339 2.857 11.1247 2.857 11.4278V14.8568H1.143V5.7138H2.857V7.4278C2.857 7.73094 2.97742 8.02167 3.19178 8.23602C3.40613 8.45038 3.69686 8.5708 4 8.5708H7.429C7.73214 8.5708 8.02287 8.45038 8.23722 8.23602C8.45158 8.02167 8.572 7.73094 8.572 7.4278V6.0568L10.286 8.1998V14.8568H8.572Z"
                :fill="fill" />
            <path
                d="M14.8569 10.286H13.1429V9.143H14.8569V1.143H3.42889V2.857H2.28589V1.143C2.28589 0.839857 2.40631 0.549131 2.62067 0.334777C2.83502 0.120423 3.12575 0 3.42889 0H14.8569C15.16 0 15.4508 0.120423 15.6651 0.334777C15.8795 0.549131 15.9999 0.839857 15.9999 1.143V9.143C15.9999 9.44614 15.8795 9.73687 15.6651 9.95122C15.4508 10.1656 15.16 10.286 14.8569 10.286Z"
                :fill="fill" />
            <path
                d="M10.2859 2.28613H13.7149V3.42913H10.2859V2.28613ZM11.4289 4.57213H13.7149V5.71513H11.4289V4.57213Z"
                :fill="fill" />
        </svg>
        <svg v-if="name === 'account'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-pie-chart">
            <path
                d="M21.21 15.89A10 10 0 1 1 8 2.83">
            </path>
            <path d="M22 12A10 10 0 0 0 12 2v10z">
            </path>
        </svg>
        <svg v-if="name === 'bell'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-bell">
            <path
                d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9">
            </path>
            <path d="M13.73 21a2 2 0 0 1-3.46 0">
            </path>
        </svg>
        <svg v-if="name === 'setting'"
            xmlns="http://www.w3.org/2000/svg"
            width="24" height="24"
            viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-settings">
            <circle cx="12" cy="12" r="3">
            </circle>
            <path
                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
            </path>
        </svg>

        <svg v-if="name === 'clode-button'"
            class="svg-inline--fa fa-xmark"
            aria-hidden="true" focusable="false"
            data-prefix="fas" data-icon="xmark"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512">
            <path fill="currentColor"
                d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
            </path>
        </svg>
    </div>
</template>

<script>
export default {
    name: "Icons",
    props: {
        name: {
            type: String,
            default: 'file'
        },
        fill: {
            type: String,
            default: '#092c4c'
        }
    },

}
</script>

<style scoped>

</style>